import { Box, Card, Container, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import logo from "../assets/indied.png";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const theme = useTheme();

  return (
    <Box
      flexGrow={1}
      sx={{ backgroundColor: theme.palette.primary.main, paddingY: 3 }}
    >
      <Container maxWidth="xs">
        <Box display="flex" justifyContent="center">
          <img src={logo} alt="logo" />
        </Box>
      </Container>
      <Container maxWidth="sm">
        <Typography
          sx={{
            marginTop: 3,
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Bienvenida/o a Indied, una herramienta 100% gratuita que, a través de
          indicadores y rúbricas, te ayudará a evaluar intervenciones educativas
          con enfoque de ciudadanía global.
        </Typography>
        <Typography
          sx={{
            marginY: 2,
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Regístrate si no tienes usuario, lee el manual para crear tu rúbrica
          de indicadores y descárgatela para ponerla en práctica.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          sx={{ marginY: 1 }}
        >
          <Link
            href="#"
            target="_blank"
            sx={{
              color: "#f59d00",
              textDecoration: "underline",
            }}
          >
            Manual de usuario
          </Link>
          <Link
            href="#"
            target="_blank"
            sx={{
              color: "#f59d00",
              textDecoration: "underline",
            }}
          >
            Guía didáctica Indied
          </Link>
        </Box>
      </Container>
      <Container maxWidth="xs">
        <Card>
          <Outlet />
        </Card>
      </Container>
    </Box>
  );
};

export default AuthLayout;
